import React, { useState, useEffect, useContext } from 'react';
import { CreditcardContext } from '../../context/creditcard.context';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../utils/navigate';
import Layout from '../../components/responsive/layout';
import { isGreaterThan } from '../../utils/functions';
import pages from '../../constants/pages.json';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../context/lang.context';
import { CompanyContext } from '../../context/company.context';
import Portrait from '../../components/responsive/portrait';
import Ode from '../../components/forms/ode';
import appService from '../../services/endpoints';

const OdePage = ({ getToken }: PageProps) => {
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          ODE {
            TITLE_1
            TITLE_2
            TITLE_3
          }
        }
      }
    }
  `);

  const { infoUser, timeStamp } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();
  const [communicationToken, setCommunicationToken] = useState('');

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else if (!infoUser?.expirationTime) {
      appService
        .getDynamicUserData(token)
        .then((res: any) => setCommunicationToken(res.form_data.data.communication_token))
        .catch(err => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      const state = { expired: { state: { messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL } } };
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, state);
    }
  }, [infoUser, timeStamp]);

  return (
    <Layout>
      <Portrait>
        <section className="content text-center">
          <h1 className="heading mx-auto text-white font-thin">
            {t(data).ODE.TITLE_1}
            <b className="font-bold">{t(data).ODE.TITLE_2}</b>
            {t(data).ODE.TITLE_3}
          </h1>
          <img src={getPublicURL(`/theme/assets/images/ode/dni.svg`)} alt="dni" className="w-full my-5" />
        </section>
      </Portrait>
      <section className="content">
        {communicationToken && <Ode communicationToken={communicationToken} />}
      </section>
    </Layout>
  );
};

export default OdePage;
