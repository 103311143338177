import React, { useContext } from 'react';
import MaskedInput from 'react-text-mask';
import { createMask } from '../../../utils/masks';
import { ChangeEvent } from 'react';
import { ArrowSelect, SelectStyled, WraperInputSelect, WrapperSelect } from '../../inputs/select/styles';
import { InputWrapper, Title, Wrapper } from '../../inputs/input/styles';
import { documentTypeList } from '../dynamic-user/utils/format';
import { graphql, useStaticQuery } from 'gatsby';
import { LangContext } from '../../../context/lang.context';

type DniProps = {
  formik: any;
  required?: boolean;
  borderColor?: string;
  textColor?: string;
  withCombo: boolean;
  withInputTitle: boolean;
  inputTitle?: string;
  withBorderError?: boolean;
  color?: 'black';
};

export const DniField = ({
  formik,
  required,
  borderColor,
  textColor,
  withCombo,
  withInputTitle,
  inputTitle,
  withBorderError,
  color,
}: DniProps) => {
  const { t } = useContext(LangContext);
  const blackColor = '#000000';
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                TITLE
                TYPE {
                  LABEL
                  PLACEHOLDER
                }
                NUMBER {
                  LABEL_2
                }
              }
            }
          }
        }
      }
    }
  `);

  const documentNumberMask =
    formik.values.documentType === 'PASAPORTE' ? createMask('PASSPORT', 9) : createMask('NUMBER', 8);
  return (
    <div className="mb-5 grid gap-2 grid-col-2">
      <label className="block font-bold mb-4">
        {inputTitle ?? t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TITLE}
        {required && ' *'}
      </label>
      <div className={`${withCombo ? 'flex gap-4' : 'gap-4 '} `}>
        {withCombo && (
          <div>
            <WraperInputSelect
              error={formik.errors.documentType}
              touched={formik.touched.documentType}
              borderColor={color === 'black' ? blackColor : borderColor}
              textColor={color === 'black' ? blackColor : textColor}
            >
              <Title>{t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.LABEL}</Title>
              <WrapperSelect>
                <div className="relative">
                  <SelectStyled
                    paddingRight="1.5rem"
                    onChange={(e: ChangeEvent<any>) => {
                      formik.setFieldValue('documentType', e.target.value);
                      formik.setFieldValue('documentNumber', '');
                      formik.setFieldTouched('documentType', false);
                    }}
                    onFocus={() => formik.setFieldTouched('documentType', false)}
                    borderColor={color === 'black' ? blackColor : borderColor}
                    textColor={color === 'black' ? blackColor : textColor}
                  >
                    <option value="">{t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.TYPE.PLACEHOLDER}</option>
                    {documentTypeList().map(({ value, title }) => (
                      <option key={value} value={value}>
                        {title}
                      </option>
                    ))}
                  </SelectStyled>
                  <ArrowSelect className="icon-arrow" />
                </div>
              </WrapperSelect>
            </WraperInputSelect>
          </div>
        )}
        <div>
          <Wrapper
            error={formik.errors.documentNumber}
            touched={withBorderError ? formik.touched.documentNumber : formik.touched.documentType}
            borderColor={color === 'black' ? blackColor : borderColor}
            textColor={color === 'black' ? blackColor : textColor}
          >
            {withInputTitle && <Title>{t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.LABEL_2}</Title>}
            <InputWrapper
              height="2.8rem"
              borderColor={color === 'black' ? blackColor : borderColor}
              textColor={color === 'black' ? blackColor : textColor}
            >
              <MaskedInput
                mask={documentNumberMask}
                onChange={(e: ChangeEvent<any>) => {
                  formik.setFieldValue('documentNumber', e.target.value);
                  formik.setFieldTouched('documentNumber', false);
                }}
                inputMode='numeric'
                maxLength={documentNumberMask.length}
                onFocus={() => formik.setFieldTouched('documentNumber', false)}
                guide={false}
                value={formik.values.documentNumber}
                className={'p-1 outline-none'}
              />
            </InputWrapper>
          </Wrapper>
        </div>
      </div>
      <div>
        {formik.touched.documentNumber && formik.errors.documentNumber && (
          <div className="flex">
            <span className="icon-exclamation-triangle mx-1 mt-1 text-sm" />
            <p className="text-red text-xs pt-1">{formik.errors.documentNumber}</p>
          </div>
        )}
        {formik.touched.documentType && formik.errors.documentType && withCombo && (
          <div className="flex">
            <span className="icon-exclamation-triangle mx-1 mt-1 text-sm" />
            <p className="text-red text-xs pt-1">{formik.errors.documentType}</p>
          </div>
        )}
      </div>
    </div>
  );
};
