import axios, { AxiosStatic, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import env from 'env';

class DefaultService {
  protected http: AxiosStatic;

  constructor() {
    this.http = this.createAxiosInterceptor();
  }

  createAxiosInterceptor() {
    axios.interceptors.request.use(this.handleRequest, this.handleError);
    axios.interceptors.response.use(this.handleResponse, this.handleError);
    axios.defaults.headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    };
    return axios;
  }

  handleRequest(config: AxiosRequestConfig) {
    return {
      ...config,
      timeout: env.TIMEOUT_REQUEST,
    };
  }

  handleResponse(response: AxiosResponse) {
    return response;
  }

  handleError(error: AxiosError) {
    throw error;
  }
}

export default DefaultService;
