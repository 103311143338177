import React, { useContext } from 'react';
import { CompanyContext } from '../../context/company.context';

interface Props {
  float?: boolean;
}

const Topbar = ({ float }: Props) => {
  let className;
  const { getPublicURL } = useContext(CompanyContext);

  if (float) {
    className = 'absolute top-0 left-0';
  } else {
    className = 'relative h-25 md:h-36';
  }
  return (
    <div className={`w-full text-white ${className}`}>
      {!float && (
        <img
          src={getPublicURL(`/theme/assets/images/responsive/topbar-bg.svg`)}
          className='absolute w-full h-full'
        />
      )}
      <div className="relative max-w-6xl px-4 mx-auto py-4 md:py-5">
        <img src={getPublicURL(`/theme/assets/images/responsive/logo.svg`)} alt="Logo" className={`h-7 md:h-10 m-auto`} />
      </div>
    </div>
  );
};

export default Topbar;
