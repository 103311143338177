import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { DniField } from '../../../components/forms/dni-select/dni';
import * as Yup from 'yup';
import { useFormik, FormikValues } from 'formik';
import { LangContext } from '../../../context/lang.context';
import communicationService from '../../../services/communication';
import { returnWhatsapp } from '../../../utils/browser';
import Button from '../../button/button';

type OdeProps = {
  communicationToken: string;
};

const Ode = ({ communicationToken }: OdeProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          ODE {
            TITLE_1
            TITLE_2
            TITLE_3
          }
          FORM {
            VALIDATION {
              DOCUMENT_NUMBER_EMPTY_1
              DOCUMENT_NUMBER_EMPTY_2
              DOCUMENT_NUMBER_INVALID
            }
          }
          ENTER_PIN {
            BUTTON
          }
          DYNAMIC_USER_FORM {
            FIELDS {
              DOCUMENT {
                NUMBER {
                  LABEL_3
                }
              }
            }
          }
        }
      }
    }
  `);

  const formik = useFormik({
    initialValues: {
      documentNumber: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      documentNumber: Yup.string() // we use String instead of number because we need to check the length and if we use Number Yup check the value instead de length
        .required(t(data).FORM.VALIDATION.DOCUMENT_NUMBER_EMPTY_2)
        .min(7, t(data).FORM.VALIDATION.DOCUMENT_NUMBER_INVALID)
        .matches(/^[0-9]+$/, t(data).FORM.VALIDATION.DOCUMENT_NUMBER_INVALID) // Reg exp for numbers
        .transform((value, originalValue) => (/\s/.test(originalValue) ? ' ' : value)), // Yup counts blank spaces as numbers, so this function check if it has any space
    }),
    onSubmit: async (values: FormikValues) => {
      communicationService
        .postCommunicationToken({ token: communicationToken, text: values.documentNumber })
        .catch(error => {
          console.log(error);
        })
        .finally(() => returnWhatsapp());
    },
  });

  return (
    <form autoComplete="false" onSubmit={formik.handleSubmit}>
      <DniField
        formik={formik}
        required={true}
        color={'black'}
        withCombo={false}
        withInputTitle={false}
        withBorderError={true}
        inputTitle={t(data).DYNAMIC_USER_FORM.FIELDS.DOCUMENT.NUMBER.LABEL_3}
      />
      <Button color={'primary'} type="submit" className="mx-auto block leading-none">
        {t(data).ENTER_PIN.BUTTON}
      </Button>
    </form>
  );
};

export default Ode;
