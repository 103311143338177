import DefaultService from './service';

interface CommunicationTokenRequest {
  token: string;
  text: string;
}

class CommunicationService extends DefaultService {
  constructor() {
    super();
  }

  async postCommunicationToken({ token, text }: CommunicationTokenRequest) {
    try {
      // @todo: replace URL with constant; could probably be abstracted out into a provider, or something
      return await this.http.get(`/psp/web`, { params: { token, text } }).then(({ data }) => data);
    } catch (error) {
      throw error;
    }
  }
}

export default new CommunicationService();
