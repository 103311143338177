import React from 'react';
import { css, Global } from '@emotion/react';
import { Head } from '../../../src/components/header/head';
interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Head/>
      <Global
        styles={css`
          html {
            font-size: 16px;
          }
        `}
      />
      {children}
    </div>
  );
};

export default Layout;
